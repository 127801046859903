.App {
  padding: 1rem;
  max-width: 28rem;
  margin: 0 auto;
  position: relative;
  min-height: 100vh;
  padding-bottom: 5rem;
  font-family: Arial, sans-serif;
}

.app-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.app-title {
  font-size: 1.25rem;
  font-weight: bold;
  color: #ec4899;
}

.app-logo {
  width: 3rem;
  height: 3rem;
  background-color: #e5e7eb;
  border-radius: 50%;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.filter-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0.5rem 1rem;
  margin-bottom: 1rem;
  background-color: #e5e7eb;
  border: none;
  border-radius: 0.25rem;
  cursor: pointer;
}

.filter-button .icon {
  margin-right: 0.5rem;
}

.filter-overlay {
  position: fixed;
  inset: 0;
  background-color: white;
  z-index: 10;
  padding: 1rem;
}

.filter-title {
  font-size: 1.125rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.filter-select, .filter-input {
  width: 100%;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
  border: 1px solid #d1d5db;
  border-radius: 0.25rem;
}

.apply-filter-button {
  width: 100%;
  padding: 0.5rem 1rem;
  background-color: #ec4899;
  color: white;
  border: none;
  border-radius: 0.25rem;
  cursor: pointer;
}

.kita-list {
  list-style-type: none;
  padding: 0;
  margin: 0 0 1rem 0;
}

.kita-item {
  border-bottom: 1px solid #e5e7eb;
}

.kita-item:last-child {
  border-bottom: none;
}

.kita-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 0;
  cursor: pointer;
}

.kita-label {
  display: flex;
  align-items: center;
  flex-grow: 1;
}

.kita-checkbox {
  margin-right: 0.5rem;
}

.kita-distance {
  font-size: 0.875rem;
  color: #6b7280;
  margin-right: 0.5rem;
}

.expand-button {
  background: none;
  border: none;
  cursor: pointer;
  color: #3b82f6;
}

.kita-details {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;
}

.kita-details.expanded {
  max-height: 10rem;
}

.kita-tags {
  padding: 0.5rem 0 0.5rem 1.5rem;
}

.tag-pill {
  display: inline-block;
  padding: 0.25rem 0.5rem;
  border-radius: 9999px;
  font-size: 0.75rem;
  font-weight: 600;
  margin-right: 0.25rem;
  margin-bottom: 0.25rem;
}

.bg-blue-100 { background-color: #dbeafe; color: #1e40af; }
.bg-green-100 { background-color: #d1fae5; color: #065f46; }
.bg-yellow-100 { background-color: #fef3c7; color: #92400e; }

.bottom-bar {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: white;
  padding: 1rem;
  box-shadow: 0 -2px 4px rgba(0,0,0,0.1);
}

.selection-counter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
}

.counter {
  font-weight: bold;
  color: #374151;
}

.counter.over-limit {
  color: #ef4444;
}

.limit-warning {
  font-size: 0.875rem;
  color: #ef4444;
}

.submit-button {
  width: 100%;
  padding: 0.5rem 1rem;
  background-color: #14b8a6;
  color: white;
  border: none;
  border-radius: 0.25rem;
  cursor: pointer;
}

.submit-button:hover {
  background-color: #0d9488;
}

.submit-button:disabled {
  background-color: #94d3cd;
  cursor: not-allowed;
}

.icon {
  width: 1.5rem;
  height: 1.5rem;
}

.expand-icon {
  transition: transform 0.2s ease-in-out;
}

.expand-icon.rotated {
  transform: rotate(180deg);
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

.animate-fade-in {
  animation: fadeIn 0.3s ease-in-out;
}

/* ... (keep all previous styles) */

.multi-select {
  position: relative;
  width: 100%;
}

.select-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
  border: 1px solid #d1d5db;
  border-radius: 0.25rem;
  cursor: pointer;
  background-color: white;
  min-height: 2.5rem;
}

.selected-options {
  display: flex;
  flex-wrap: wrap;
  gap: 0.25rem;
}

.placeholder {
  color: #6b7280;
}

.selected-pill {
  display: inline-flex;
  align-items: center;
  background-color: #e5e7eb;
  color: #374151;
  padding: 0.25rem 0.5rem;
  border-radius: 9999px;
  font-size: 0.875rem;
}

.selected-pill button {
  background: none;
  border: none;
  color: #4b5563;
  margin-left: 0.25rem;
  cursor: pointer;
  font-size: 1rem;
  line-height: 1;
}

.arrow {
  border: solid #6b7280;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
  transition: transform 0.2s;
}

.arrow.up {
  transform: rotate(-135deg);
}

.options-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: white;
  border: 1px solid #d1d5db;
  border-top: none;
  border-radius: 0 0 0.25rem 0.25rem;
  max-height: 300px;
  overflow-y: auto;
  z-index: 10;
}

.search-container {
  padding: 0.5rem;
  border-bottom: 1px solid #e5e7eb;
}

.search-container input {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #d1d5db;
  border-radius: 0.25rem;
}

.option-category {
  padding: 0.5rem;
  border-bottom: 1px solid #e5e7eb;
}

.option-category h3 {
  margin: 0 0 0.5rem 0;
  font-size: 0.875rem;
  color: #4b5563;
}

.option {
  display: block;
  padding: 0.25rem 0;
  cursor: pointer;
}

.option:hover {
  background-color: #f3f4f6;
}

.option input {
  margin-right: 0.5rem;
}

.clear-all-btn {
  display: block;
  width: 100%;
  padding: 0.5rem;
  background-color: #f3f4f6;
  border: none;
  border-top: 1px solid #e5e7eb;
  color: #4b5563;
  cursor: pointer;
  font-size: 0.875rem;
}

.clear-all-btn:hover {
  background-color: #e5e7eb;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 90%;
  width: 400px;
  max-height: 90vh;
  overflow-y: auto;
}

.modal h2 {
  margin-top: 0;
  font-size: 1.5rem;
}

.modal p {
  margin-bottom: 15px;
}

.modal-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.modal-buttons button {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
}

.modal-buttons button:first-child {
  background-color: #4CAF50;
  color: white;
}

.modal-buttons button:last-child {
  background-color: #f44336;
  color: white;
}

@media (max-width: 480px) {
  .modal-content {
    width: 95%;
    padding: 15px;
  }

  .modal h2 {
    font-size: 1.2rem;
  }

  .modal p {
    font-size: 0.9rem;
  }

  .modal-buttons button {
    padding: 6px 12px;
    font-size: 0.9rem;
  }
}